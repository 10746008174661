import axios from '@/axios.js'

export default {
    getTracks: async (appUuid, parameters) => {
        try {
            const response = await axios.editorapi.get(`apps/v3/${appUuid}/tracks`, { params: parameters })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    exportTracks: async (appUuid, parameters) => {
        try {
            const response = await axios.editorapi.get(`apps/v3/export/${appUuid}/tracks`, { params: parameters })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getTrackActivities: async (page) => {
        try {
            const response = await axios.publicapi.get(`v4/tracks/activities`, { params: {
                page: page,
            }})
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    }
}
